var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mt-2 px-0" },
    _vm._l(_vm.flags, function (flag, index) {
      return _c(
        "b-col",
        {
          key: index,
          staticClass: "d-flex mb-2 w-100",
          attrs: { sm: _vm.sizeFlagInput(_vm.flags.length) },
        },
        [
          _c(
            "b-form-group",
            {
              staticClass: "w-100",
              attrs: { label: `${flag.name.toUpperCase()}` },
            },
            [
              _c("v-select", {
                attrs: {
                  label: "name",
                  placeholder: "...",
                  options: flag.projectFlagItem,
                  reduce: (option) => option.id,
                  disabled: !_vm.canEditExpense,
                },
                on: {
                  input: function ($event) {
                    return _vm.flagChanged($event, flag.flagId)
                  },
                },
                model: {
                  value: flag.customFlagSelected,
                  callback: function ($$v) {
                    _vm.$set(flag, "customFlagSelected", $$v)
                  },
                  expression: "flag.customFlagSelected",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }