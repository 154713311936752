<template>
	<b-row class="mt-2 px-0">
		<!-- <pre>{{ flags }}</pre> -->
		<b-col :sm="sizeFlagInput(flags.length)" class="d-flex mb-2 w-100" v-for="(flag, index) in flags" :key="index">
			<b-form-group :label="`${flag.name.toUpperCase()}`" class="w-100">
				<v-select
					v-model="flag.customFlagSelected"
					label="name"
					placeholder="..."
					:options="flag.projectFlagItem"
					:reduce="(option) => option.id"
					:disabled="!canEditExpense"
					@input="flagChanged($event, flag.flagId)"
				/>
			</b-form-group>
		</b-col>
	</b-row>
</template>
<script>
import GlobalMixin from '@/mixins/global.mixin';
// for flags
import ExpensesFlagsMixin from '@/mixins/expensesFlags.mixin';
import _ from 'lodash';

export default {
	name: 'FlagInputList',
	mixins: [GlobalMixin, ExpensesFlagsMixin],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		},
		expenseName: {
			type: String,
			required: false,
			default: null
		},
		selectedFlagIds: {
			type: Array,
			required: false,
			default: () => []
		},
		clearSelected: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {};
	},
	methods: {
		flagChanged(payload, flagId) {
			this.customFlagChanged(payload, flagId);

			if (!_.isNil(this.expenseName)) {
				this[this.expenseName].flagItemId = +payload;
			}

			// console.log({
			// 	newValueCustomFlags: this.newValueCustomFlags
			// });
			this.$emit('flag-input-list:inputs', [...this.newValueCustomFlags]);
		},
		setFilterFlagIds(flagIds) {
			if (flagIds.length > 0) {
				this.flags = this.flags.map((flag) => {
					let findId = flagIds.filter((flagId) => flag.projectFlagItem.findIndex((item) => +item.id === flagId.flagId) !== -1);

					if (findId.length > 0) {
						this.newValueCustomFlags.push({
							flagId: +flag.flagId,
							item: flag.projectFlagItem.filter((item) => +item.id === findId[0].flagId)[0]
						});
					}

					return {
						...flag,
						customFlagSelected: findId.length > 0 ? findId[0].flagId.toString() : null
					};
				});
			}
		}
	},
	watch: {
		items: {
			async handler(value) {
				let flagItems = value;
				this.flags = [];

				for (const flagItem of flagItems) {
					if (flagItem.projectFlagItem.length > 0) {
						this.flags.push(flagItem);
					}
				}

				await new Promise((resolve) => {
					setTimeout(() => {
						resolve(this.setFilterFlagIds(this.selectedFlagIds));
					}, 200);
				});
			},
			deep: true,
			immediate: true
		},
		clearSelected: {
			handler(value) {
				if (value === true) {
					this.flags = this.flags.map((flag) => {
						return {
							...flag,
							customFlagSelected: null
						};
					});
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped></style>
