import { computed } from 'vue';
import { noTilde } from '~utils';
import _ from 'lodash';
import {
	TRANSPORTATION_MEAN_DETAIL_MENU,
	TRANSPORTATION_MEAN_MENU,
	WASTE_TYPE_MENU,
	CARBON_PRODUCTION_LOGISTIC_TYPE,
	GEOGRAPHIC_AREA_MENU,
	ACTIVITY_TYPE_MENU,
	REPORTING_TYPE_MENU,
	SPE_VEHICLE_GENERATOR_TRUCK_TYPE,
	SPE_VEHICLE_PRODUCTION_TRUCK_TYPE,
	SPE_VEHICLE_TRAILER_TYPE,
	SPE_GENERATOR_TYPE,
	SPE_PRODUCTION_ROOMS_TYPE,
	SPE_TRAILER_TYPE
} from '@/shared/constants';
import { getSpecialEquipmentList } from '@/cruds/special-equipment.crud';

export default {
	name: 'CarbonMenuMixin',
	computed: {
		//Accomodation Menu Type
		hotelOptions() {
			let menus = this.FormMenu(1415);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 149,
					text: this.FormMSG(49, 'Hotel'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		houseOptions() {
			let menus = this.FormMenu(1417);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 150,
					text: this.FormMSG(50, 'House'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		appartOptions() {
			let menus = this.FormMenu(1416);
			if (menus.length === 1) {
				menus[0].selectable = true;
			}

			return menus;
		},

		accomodationTypeOptions() {
			let menus = [];
			let headeroptions = [
				{
					value: 149,
					text: this.FormMSG(49, 'Hotel')
				},
				{
					value: 150,
					text: this.FormMSG(50, 'House')
				}
			];

			headeroptions.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			let principalOptions = [...this.appartOptions];

			for (let i = 0; i < headeroptions.length; i++) {
				if (headeroptions[i].value === 149) {
					principalOptions.push(...this.hotelOptions);
				} else if (headeroptions[i].value === 150) {
					principalOptions.push(...this.houseOptions);
				}
			}

			menus = [...principalOptions];

			return menus;
		},

		transportOptions() {
			let menus = [];
			let headeroptions = [
				{
					value: TRANSPORTATION_MEAN_MENU.BOAT,
					text: this.FormMSG(51, 'Boat')
				},
				{
					value: TRANSPORTATION_MEAN_MENU.BUS,
					text: this.FormMSG(40, 'Bus')
				},
				{
					value: TRANSPORTATION_MEAN_MENU.CAR,
					text: this.FormMSG(52, 'Car')
				},
				{
					value: TRANSPORTATION_MEAN_MENU.FREIGHT,
					text: this.FormMSG(69, 'Freight')
				},
				{
					value: TRANSPORTATION_MEAN_MENU.OTHER,
					text: this.FormMSG(53, 'Other')
				},
				{
					value: TRANSPORTATION_MEAN_MENU.SPECIAL_VEHICLE,
					text: this.FormMSG(54, 'Special vehicles')
				}
			];

			headeroptions.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			let principalOptions = [];

			for (let i = 0; i < headeroptions.length; i++) {
				if (headeroptions[i].value === TRANSPORTATION_MEAN_MENU.BOAT) {
					principalOptions.push(...this.boatOptions);
				} else if (headeroptions[i].value === TRANSPORTATION_MEAN_MENU.BUS) {
					principalOptions.push(...this.busOptions);
				} else if (headeroptions[i].value === TRANSPORTATION_MEAN_MENU.CAR) {
					principalOptions.push(...this.carOptions);
				} else if (headeroptions[i].value === TRANSPORTATION_MEAN_MENU.FREIGHT) {
					principalOptions.push(...this.freightOptions);
				} else if (headeroptions[i].value === TRANSPORTATION_MEAN_MENU.OTHER) {
					principalOptions.push(...this.otherOptions);
				} else if (headeroptions[i].value === TRANSPORTATION_MEAN_MENU.SPECIAL_VEHICLE) {
					principalOptions.push(...this.specialVehicleOptions);
				}
			}

			menus = [...principalOptions];

			return menus;
		},
		freightOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.FREIGHT);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: null,
					text: this.FormMSG(69, 'Freight'),
					disabled: true
				},
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && ![17, 18, 19].includes(menu.value);
					}
				})
			];
		},
		boatOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.BOAT);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});
			return [
				{
					value: null,
					text: this.FormMSG(51, 'Boat'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		busOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.BUS);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});
			return [
				{
					value: null,
					text: this.FormMSG(40, 'Bus'),
					disabled: true
				},
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && menu.value !== 5;
					}
				})
			];
		},
		carOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.CAR);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});
			return [
				{
					value: null,
					text: this.FormMSG(52, 'Car'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		otherOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.OTHER);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: null,
					text: this.FormMSG(53, 'Other'),
					disabled: true
				},
				...menus.filter((menu) => {
					if (!this.hideMenuesNotTwoWheels) {
						return menu.text !== 'not found';
					} else {
						return menu.text !== 'not found' && ![9, 10, 12, 14, 15].includes(menu.value);
					}
				})
			];
		},
		specialVehicleOptions() {
			let menus = this.FormMenu(TRANSPORTATION_MEAN_MENU.SPECIAL_VEHICLE);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});
			return [
				{
					value: null,
					text: this.FormMSG(54, 'Special vehicles'),
					disabled: true
				},
				...menus
			];
		},

		allTransportationOptions() {
			const options = [];

			const computedOptions = {
				freight: this.freightOptions,
				boat: this.boatOptions,
				bus: this.busOptions,
				car: this.carOptions,
				other: this.otherOptions,
				specialVehicle: this.specialVehicleOptions
			};

			for (const key in computedOptions) {
				if (Object.hasOwnProperty.call(computedOptions, key)) {
					options.push(...computedOptions[key]);
				}
			}

			return options;
		},

		//Waste Menu Type
		wasteTypeOptions() {
			return [{ value: 0, text: this.FormMSG(912, 'Please select ...') }, ...this.FormMenu(WASTE_TYPE_MENU)];
		},
		//MEAL NO MENU

		//HMC
		hmcOptions() {
			return this.FormMenu(11008);
		},

		//SET CREATION
		reasonSetCreationOptions() {
			return this.FormMenu(11009);
		},

		//TECHNICHAL PRODUCTION MEANS
		technicalProductionOptions() {
			return this.FormMenu(11010);
		},

		//TRANSPORTATION SHOOTING MEANS
		transportationShootingOptions() {
			return this.FormMenu(11011);
		},

		//FUEL TYPE OPTIONS
		fuelTypeOptions() {
			return this.FormMenu(11012);
		},

		//Production logistics
		productionLogisticsOptions() {
			return this.FormMenu(CARBON_PRODUCTION_LOGISTIC_TYPE);
		},

		//Group Transportation
		geographicAreaOptions() {
			return [{ text: this.FormMSG(121, 'Please select ...'), value: 0 }, ...this.FormMenu(GEOGRAPHIC_AREA_MENU)];
		},

		//Vehicle Form
		vehicleType() {
			return this.FormMenu(1447);
		},

		//Insurance and services
		co2TypeInsuranceAndService() {
			return this.FormMenu(1448);
		},

		//POST PRODUCTION ACTIVITIES
		activityTypeOptions() {
			return [{ text: this.FormMSG(152, 'Please select ...'), value: 0 }, ...this.FormMenu(ACTIVITY_TYPE_MENU)];
		},
		//Subtype
		optionsForCountries() {
			let options = this.FormMenu(8);
			options.sort((a, b) => {
				if (noTilde(a.text) < noTilde(b.text)) {
					return -1;
				}
				if (noTilde(a.text) > noTilde(b.text)) {
					return 1;
				}

				return 0;
			});

			options = [
				{
					value: 0,
					text: this.FormMSG(1, 'Select a country ...')
				},
				...options
			];

			return options;
		},
		//Additionnal subtype
		statusOptions() {
			return this.FormMenu(TRANSPORTATION_MEAN_DETAIL_MENU.HIRE_RENTAL);
		},
		reportingTypeOptions() {
			return [{ text: this.FormMSG(152, 'Please select ...'), value: 0 }, ...this.FormMenu(REPORTING_TYPE_MENU)];
		},

		transportationSubTypePlaneType() {
			return this.getSubTypeOptions(TRANSPORTATION_MEAN_DETAIL_MENU.PLANE, 986);
		},
		transportationSubTypeScooterType() {
			return this.getSubTypeOptions(TRANSPORTATION_MEAN_DETAIL_MENU.SCOOTER, 986);
		},
		transportationSubTypeTrainType() {
			return this.getSubTypeOptions(TRANSPORTATION_MEAN_DETAIL_MENU.TRAIN, 986);
		},
		transportationSubTypeVanType() {
			return this.getSubTypeOptions(TRANSPORTATION_MEAN_DETAIL_MENU.VAN, 986);
		},
		transportationSubTypePlaneTypeAditionalType() {
			return this.getSubTypeOptions(TRANSPORTATION_MEAN_DETAIL_MENU.PLANE_TYPE, 986);
		},
		transportationSubTypeCarType() {
			return this.getSubTypeOptions(TRANSPORTATION_MEAN_DETAIL_MENU.CAR, 987);
		},

		transportationSubTypeScooterType() {
			return this.getSubTypeOptions(TRANSPORTATION_MEAN_DETAIL_MENU.SCOOTER, 987); // Assuming you have a scooter menu
		}
	},
	methods: {
		getSubTypeOptions(menuNumber, defaultMessageKey) {
			let options = this.FormMenu(menuNumber);
			options.sort((a, b) => {
				if (noTilde(a.text) < noTilde(b.text)) {
					return -1;
				}
				if (noTilde(a.text) > noTilde(b.text)) {
					return 1;
				}
				return 0;
			});

			options = [
				{
					value: 0,
					text: this.FormMSG(defaultMessageKey, 'Select a type ...')
				},
				...options
			];

			return options;
		},
		async getEquipmentOptions(filterActiveType) {
			let equipmentOptions = [];

			if (filterActiveType === SPE_VEHICLE_GENERATOR_TRUCK_TYPE) {
				await getSpecialEquipmentList({ type: SPE_GENERATOR_TYPE, arrayType: [{ type: 3 }] }).then((result) => {
					console.log('equipments', result);
					equipmentOptions = result;
				});
			} else if (filterActiveType === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE) {
				await getSpecialEquipmentList({
					type: SPE_PRODUCTION_ROOMS_TYPE,
					measurementOption: false,
					arrayType: [{ type: 3 }, { type: 4 }, { type: 5 }]
				}).then((result) => {
					console.log('equipments', result);
					equipmentOptions = result;
				});
			} else if (filterActiveType === SPE_VEHICLE_TRAILER_TYPE) {
				await getSpecialEquipmentList({ type: SPE_TRAILER_TYPE }).then((result) => {
					console.log('equipments', result);
					equipmentOptions = result;
				});
			}

			return equipmentOptions.map((item) => ({
				value: +item.id,
				text: item.name
			}));
		}
	}
};
