<template>
	<div class="animated fadeIn" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
		<b-row>
			<b-col cols="6" class="d-flex align-items-center">
				<button
					class="d-flex justify-content-end align-items-center text-color-blue-streak fw-500 fs-14 btn-transparent mr-3"
					@click="handleClickShowFilter(!tagVisible)"
				>
					<div v-if="tagVisible">{{ FormMSG(25, 'Hide filter') }}</div>
					<div v-if="!tagVisible">{{ FormMSG(26, 'Show filter') }}</div>
					<chevron-up v-if="tagVisible" :size="20" color="#225CBD" class="ml-1" />
					<chevron-down v-if="!tagVisible" :size="20" color="#225CBD" class="ml-1" />
				</button>
			</b-col>
			<b-col cols="6">
				<div class="float-right">
					<div class="d-inline-block d-flex justify-content-end align-items-center">
						<slot name="expense-review-mode" />
						<b-button
							v-if="!hideButtonRefreshData"
							variant="success"
							class="w-200-px mr-2 d-flex justify-content-center align-items-center"
							@click="handleClickRefreshData"
						>
							<component :is="getLucideIcon('RefreshCw')" :size="16" class="mr-2" />
							<div style="margin-top: 1px">{{ FormMSG(136, 'Refresh data') }}</div>
						</b-button>
						<b-button
							v-if="!hideButtonExport"
							class="w-200-px d-flex justify-content-center align-items-center"
							variant="primary"
							@click="handleClickExportSelection"
						>
							<component :is="getLucideIcon('Download')" :size="16" class="mr-2" />
							<div style="margin-top: 1px">{{ FormMSG(29, 'Export selection') }}</div>
						</b-button>
						<b-button
							v-if="showImport"
							class="ml-2 d-flex justify-content-end align-items-center"
							variant="primary"
							@click="redirectToImportScreen"
						>
							<div style="margin-top: 1px; width: 130px">{{ FormMSG(290, 'Import') }}</div>
						</b-button>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-collapse :visible="tagVisible" class="form mt-2">
			<fieldset v-if="!hideDate" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(856, 'General') }}
				</legend>
				<b-row>
					<!-- <pre>{{ filterActive }}</pre> -->
					<b-col cols="3">
						<b-form-group :label="FormMSG(41, 'Start date')">
							<v-date-picker v-model="filterActive.startDate" :locale="lang" :masks="masks" :attributes="attrs" @dayclick="handleInputStartDate">
								<template v-slot="{ inputValue, togglePopover }">
									<b-input-group>
										<b-form-input
											:value="inputValue"
											class="br-none form-control"
											:placeholder="FormMSG(896, 'DD/MM/YYYY')"
											:class="{
												'is-invalid': isSubmitted && $v.filterActive.startDate.$error
											}"
											v-mask="'##/##/####'"
											readonly
											@click="togglePopover()"
										/>
										<b-input-group-append>
											<b-input-group-text class="cursor-pointer bg-color-white input-group-text-bg-white" @click="togglePopover()">
												<component :is="getLucideIcon('Calendar')" color="rgba(6, 38, 62, .6)" :size="18" :stroke-width="2.25" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
									<div v-if="isSubmitted && $v.filterActive.startDate.$error" class="invalid-feedback">
										{{ FormMSG(897, 'Start date, a valid date is required') }}
									</div>
								</template>
							</v-date-picker>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(43, 'End date')">
							<v-date-picker
								v-model="filterActive.endDate"
								:available-dates="{
									start: moment(filterActive.startDate).format('YYYY-MM-DD'),
									end: null
								}"
								:locale="lang"
								:masks="masks"
								:attributes="attrs"
								@dayclick="handleInputEndDate"
							>
								<template v-slot="{ inputValue, togglePopover }">
									<b-input-group>
										<b-form-input
											:value="inputValue"
											class="br-none"
											:placeholder="FormMSG(896, 'DD/MM/YYYY')"
											:class="{
												'is-invalid': isSubmitted && $v.filterActive.endDate.$error
											}"
											v-mask="'##/##/####'"
											readonly
											@click="togglePopover()"
										/>
										<b-input-group-append>
											<b-input-group-text class="cursor-pointer bg-color-white input-group-text-bg-white" @click="togglePopover()">
												<component :is="getLucideIcon('Calendar')" color="rgba(6, 38, 62, .6)" :size="18" :stroke-width="2.25" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
									<div v-if="isSubmitted && $v.filterActive.endDate.$error" class="invalid-feedback">
										{{ FormMSG(898, 'End date, a valid date is required') }}
									</div>
								</template>
							</v-date-picker>
						</b-form-group>
					</b-col>
					<b-col v-if="!hideCoTwoDetail" cols="3">
						<b-form-group class="mb-3" :label="FormMSG(214, 'User')">
							<v-select
								:class="filterActivePersonId ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.personId"
								:options="showJobRequestEntryDetailSection ? jobRequestUser : users"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(218, 'Select a user')"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<!--CARBON-->
			<fieldset v-if="!hidePersonSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(146, 'Person') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(35, 'Department')">
							<v-select
								:class="filterActiveUserDepartment ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.userDepartment"
								:options="[{ value: -1, message: FormMSG(285, 'All departments') }, ...allDepartements]"
								label="message"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(36, 'Select department')"
								:clearable="false"
								@input="handleChangeDepartment"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(37, 'Function')">
							<v-select
								:class="filterActiveUserFunction ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.userFunction"
								:options="functionNames"
								label="message"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(38, 'Select function')"
								:disabled="filterActive.userDepartment === null || filterActive.userDepartment === -1"
								@input="handleFunctionInput"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(214, 'User')">
							<v-select
								:class="filterActivePersonId ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.personId"
								:options="showJobRequestEntryDetailSection ? jobRequestUser : users"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(218, 'Select a user')"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<!--BUDGET-->
			<fieldset v-if="!hideExpenseDetailsSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ titleExpenseDetail }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(295, 'Status')">
							<v-select
								:class="filterActiveStatus ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.status"
								:options="statuses"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(265, 'Select a status')"
							/>
						</b-form-group>
					</b-col>
					<b-col v-if="useDepartmentForExpense && !hideExpenseDetailsDepartment" cols="3">
						<b-form-group :label="FormMSG(35, 'Department')">
							<v-select
								:class="filterActiveDepartment ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.department"
								:options="[{ value: -1, message: FormMSG(285, 'All departments') }, ...allDepartements]"
								label="message"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(296, 'Select a department')"
								@input="handleInputDepartmentExpense($event, true)"
							/>
						</b-form-group>
					</b-col>
					<b-col v-if="!hideExpenseDetailsCategory" cols="3">
						<b-form-group :label="FormMSG(151, 'Category')">
							<treeselect
								:class="filterActiveCategory ? 'active-filter-input-tree-select' : ''"
								v-model="filterActive.category"
								:multiple="false"
								:options="mapCategories"
								:disable-branch-nodes="true"
								:placeholder="FormMSG(297, 'Select a category')"
								:clearable="false"
							>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="node.label">
										{{ node.label }}
									</div>
								</div>
							</treeselect>
						</b-form-group>
					</b-col>
					<b-col v-if="filterType === 2 || filterType === 4" cols="3">
						<b-form-group :label="FormMSG(347, 'Type')">
							<v-select
								:class="filterActiveCo2Detail1 ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.type"
								:options="typeExpenses"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(326, 'Select a type')"
							/>
						</b-form-group>
					</b-col>
					<b-col v-if="!hideProcessFilter">
						<b-form-group :label="FormMSG(514, 'Processed')" v-slot="{ ariaDescribedby }">
							<!--<b-form-group :label="FormMSG(12365, 'Report')">
								<b-form-select @change="handleChangeReportOption" v-model="filterActive.arrayType" :options="arrayTypeOptions"> </b-form-select>
							</b-form-group>-->
							<b-form-radio-group
								id="radio-group-2"
								v-model="filterActive.processed"
								:aria-describedby="ariaDescribedby"
								name="radio-sub-component"
							>
								<b-form-radio :value="false" class="mt-2">{{ FormMSG(515, 'No') }}</b-form-radio>
								<b-form-radio :value="true" class="ml-2 mt-2">{{ FormMSG(516, 'Yes') }}</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
					<b-col v-if="!hideInvoicedToProduction">
						<b-form-group :label="FormMSG(553, 'Invoiced to production')" v-slot="{ ariaDescribedby }">
							<b-form-radio-group
								id="radio-group-3"
								v-model="filterActive.invoicedToProduction"
								:aria-describedby="ariaDescribedby"
								name="radio-sub-component-2"
							>
								<b-form-radio :value="false" class="mt-2">{{ FormMSG(515, 'No') }}</b-form-radio>
								<b-form-radio :value="true" class="ml-2 mt-2">{{ FormMSG(516, 'Yes') }}</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<fieldset v-if="!hideSupplierSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(149, 'Supplier') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(212, 'Supplier')">
							<v-select
								:class="filterActiveSupplier ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.supplierId"
								:options="suppliers"
								label="name"
								:reduce="reduceSupplier"
								:placeholder="FormMSG(213, 'Select a supplier')"
								@input="handleInputSupplier"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(30, 'Country')">
							<v-select
								:class="filterActiveCountry ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.country"
								:options="optionsForCountries"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(31, 'Select country')"
								@input="handleChangeCountry"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(32, 'State / Region')">
							<v-select
								:class="filterActiveState ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.state"
								:options="stateNames"
								:placeholder="FormMSG(128, 'Select state')"
								label="text"
								:reduce="(option) => option.value"
								:disabled="!filterActive.country"
								@input="handleInputState"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(33, 'City')">
							<b-form-input v-model="filterActive.city" :placeholder="FormMSG(33, 'City')" />
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<!--CARBON-->
			<fieldset v-if="!hideEntryDetailSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(521, 'EntryDetail') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(522, 'Source')">
							<v-select
								:class="filterActiveSource ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.source"
								label="text"
								:options="sourceOptions"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(523, 'Select a source')"
								:clearable="false"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(151, 'Category')">
							<treeselect
								:class="filterActiveCategory ? 'active-filter-input-tree-select' : ''"
								v-model="filterActive.category"
								:multiple="false"
								:options="mapCategories"
								:disable-branch-nodes="true"
								:placeholder="FormMSG(297, 'Select a category')"
								:clearable="false"
								@input="handleInputCategory"
							>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="node.label">
										{{ node.label }}
									</div>
								</div>
							</treeselect>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(212, 'Supplier')">
							<v-select
								:class="filterActiveSupplier ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.supplierId"
								:options="suppliers"
								label="name"
								:reduce="reduceSupplier"
								:placeholder="FormMSG(213, 'Select a supplier')"
								:clearable="false"
								@input="handleInputSupplier"
							/>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(526, 'Review status')">
							<treeselect
								:class="filterActiveReviewStatus ? 'active-filter-input-tree-select' : ''"
								:options="reviewStatusOptions"
								:v-model="filterActive.reviewStatus"
								:value="filterActive.reviewStatus"
								:placeholder="FormMSG(894, 'Please select ...')"
								:multiple="true"
								flat
								:clearable="false"
								@input="handleInputReviewStatus"
							>
								<div
									slot="value-label"
									slot-scope="{ node }"
									:title="setTextValue(node.label)"
									class="d-flex align-items-center justify-content-center"
									style="height: 12px !important"
								>
									<div style="font-size: 10px !important">{{ setTextValue(node.label) }}</div>
								</div>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="setText(node.label)">{{ setText(node.label) }}</div>
								</div>
							</treeselect>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(289, 'Template')">
							<v-select
								:class="filterActiveTemplate ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.templateId"
								:multiple="false"
								:options="carbonTemplates"
								:placeholder="FormMSG(397, 'Please select ...')"
								label="name"
								:reduce="(option) => +option.id"
								:clearable="false"
							>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="node.label">
										{{ node.label }}
									</div>
								</div>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(392, 'Forecast/Final report')">
							<treeselect
								:class="filterActiveArrayType ? 'active-filter-input-tree-select' : ''"
								:options="arrayTypeOptions"
								:v-model="filterActive.arrayType"
								:value="filterActive.arrayType"
								:placeholder="FormMSG(894, 'Please select ...')"
								:multiple="true"
								flat
								:clearable="false"
								@input="handleInputArrayType"
							>
								<div
									slot="value-label"
									slot-scope="{ node }"
									:title="setTextValue(node.label)"
									class="d-flex align-items-center justify-content-center"
									style="height: 12px !important"
								>
									<div style="font-size: 10px !important">{{ setTextValue(node.label) }}</div>
								</div>
								<div slot="option-label" slot-scope="{ node }">
									<div class="treeselect-label" :title="setText(node.label)">{{ setText(node.label) }}</div>
								</div>
							</treeselect>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<fieldset v-if="!hideCoTwoDetail" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(851, 'CO2 detail') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(524, 'CO2 Type')">
							<v-select
								:class="filterActiveCo2Type ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.co2Type"
								label="text"
								:options="coTwoTypeOptions"
								:placeholder="FormMSG(525, 'Select a category')"
								:reduce="(option) => option.value"
								:clearable="false"
								@input="handleChangeCo2Type"
							/>
						</b-form-group>
					</b-col>
					<b-col v-if="typeOptions !== null" cols="3">
						<b-form-group :label="FormMSG(853, 'CO2 detail 1 ')">
							<v-select
								:class="filterActiveCo2Detail1 ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.type"
								label="text"
								:options="typeOptions"
								:placeholder="FormMSG(525, 'Select a category')"
								:reduce="(option) => option.value"
								:clearable="false"
								:selectable="selectableTypeItem"
								@input="handleChangeType"
							>
								<template #option="option">
									<div
										:class="`${
											(option.disabled && option.disabled === true) || (option.selectable && option.selectable === true)
												? 'text-bold'
												: 'text-color ml-3'
										}`"
									>
										{{ option.text }}
									</div>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col v-if="subTypeOptions !== null" cols="3">
						<b-form-group :label="FormMSG(854, 'CO2 detail 2')">
							<v-select
								:class="filterActiveCo2Detail2 ? 'active-filter-input-v-select' : ''"
								:value="filterActive.subType"
								label="text"
								:options="subTypeOptions"
								:placeholder="FormMSG(525, 'Select a category')"
								:reduce="(option) => option.value"
								:clearable="false"
								@input="handleChangeSubType"
							>
								<template #option="option">
									{{ option.text }}
								</template>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col v-if="additionalSubTypeOptions !== null" cols="3">
						<b-form-group :label="FormMSG(855, 'CO2 detail 3')">
							<v-select
								:class="filterActiveCo2Detail3 ? 'active-filter-input-v-select' : ''"
								:value="filterActive.additionalSubType"
								label="text"
								:options="additionalSubTypeOptions"
								:placeholder="FormMSG(525, 'Select a category')"
								:reduce="(option) => option.value"
								:clearable="false"
								@input="handleChangeAdditionalSubType"
							>
								<template #option="option">
									{{ option.text }}
								</template>
							</v-select>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<fieldset v-if="showJobRequestEntryDetailSection" class="scheduler-border border-groove-blue-streak pb-0">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(52102, 'Entry Detail') }}
				</legend>
				<b-row>
					<b-col cols="3">
						<b-form-group :label="FormMSG(2101, 'Status')">
							<v-select
								:class="filterActiveStatus ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.status"
								:options="jobStatuses"
								label="text"
								:reduce="(option) => option.value"
								:placeholder="FormMSG(2143, 'Select a status')"
								@input="(option) => option.value"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(2108, 'Project Name')">
							<v-select
								:class="filterActiveProjectId ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.projectId"
								:options="projectList"
								label="titre"
								:reduce="(option) => option.id"
								:placeholder="FormMSG(2135, 'Select a project')"
							/>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group :label="FormMSG(2102, 'Company Name')">
							<v-select
								:class="filterActiveSupplier ? 'active-filter-input-v-select' : ''"
								v-model="filterActive.supplierId"
								:options="suppliers"
								label="name"
								:reduce="reduceSupplier"
								:placeholder="FormMSG(213, 'Select a supplier')"
								@input="handleInputSupplier"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</fieldset>
			<!--FLAGS-->
			<fieldset class="scheduler-border border-groove-blue-streak pb-0" v-if="!hideFlagFilter && canEditExpense">
				<legend class="scheduler-border text-color-blue-streak">
					{{ FormMSG(895, 'Flags') }}
				</legend>
				<flag-inputs
					:items="flags"
					:selected-flag-ids="filterActive.flagIds"
					:clear-selected="clearSelectedFlags"
					@flag-input-list:inputs="onFlagSelected"
				/>
			</fieldset>
			<b-row>
				<b-col cols="2" offset="10">
					<b-button variant="success" block :disabled="!canApplyFilter" @click="handleApplyFilter">
						{{ FormMSG(291, 'Apply filter') }}
					</b-button>
				</b-col>
			</b-row>
		</b-collapse>
		<b-row class="form pb-4" :class="tagVisible ? '' : 'pt-4'">
			<b-col cols="12" class="d-flex align-items-center pt-4">
				<b-button v-if="haveFilter" variant="outline-primary" size="sm" @click="resetFilter">
					{{ FormMSG(28, 'Reset filter') }}
				</b-button>
				<button
					class="d-flex justify-content-end align-items-center fw-500 fs-14 btn-transparent"
					:class="{
						'text-color-tree-sap': !haveFilter,
						'text-color-blue-streak': haveFilter,
						'pl-3': haveFilter
					}"
				>
					<info :size="18" class="mr-1" />
					<div v-if="haveFilter">{{ FormMSG(27, 'Active filter') }}</div>
					<div v-if="!haveFilter">{{ FormMSG(101, 'No active filter') }}</div>
				</button>
				<div v-if="haveFilter">
					<b-row>
						<b-col v-for="filter in filterFunctions" :key="filter.name">
							<div v-if="filter.function && filter.value !== null" class="wrap-status">
								<div class="status info" style="font-size: 0.85rem">{{ filter.value }}</div>
							</div>
						</b-col>
					</b-row>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { ChevronUp, ChevronDown, Info, CalendarDays, Download, RefreshCw, SlidersHorizontal, PlusCircle, AlertTriangle } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
import { mapGetters, mapActions } from 'vuex';
// import gql from 'graphql-tag';
import { getSuppliersCountries, getSuppliersStates } from '@/cruds/suppliers.crud';
import { getSuppliers } from '@/cruds/suppliers.crud';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
// import { store } from '@/store';
import { getUsers, getListUserInJobRequest } from '@/cruds/users.crud';
import { getAllProjects } from '@/cruds/project.crud';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import { removeAttributeTree, dateFrValid } from '@/shared/utils';
import GlobalFilter from '@/components/Modals/GlobalFilter';
import BudgetMixin from '@/components/Budget/budget.mixin';
import CarbonMenuMixin from '@/components/Budget/carbon.menu.mixin';
import { getFunctions } from '@/cruds/department.crud';
import {
	CARBON_VERIFIED_STATUS_MENU,
	SPE_VEHICLE_GENERATOR_TRUCK_TYPE,
	SPE_VEHICLE_PRODUCTION_TRUCK_TYPE,
	SPE_VEHICLE_TRAILER_TYPE,
	FILTER_TYPE,
	FLAG_TYPE
} from '@/shared/constants';
import { getCarbonTemplates } from '@/cruds/carbon.crud';
import TransportTypeSelector from '@/modules/transports/components/TransportTypeSelector.vue';
import { noTilde } from '~utils';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
// for flags
import { getProjectFlags } from '@/cruds/flag.crud';
import expensesFlagsMixin from '@/mixins/expensesFlags.mixin';
import FlagInputs from '@/components/Finance/FlagInputList';
import { store } from '@/store';

/*
 * 0: Filter for budget per category
 * 1: Filter for budget salaries
 * 2: Filter for budget expenses
 * 3: Filter for budget purchase orders
 * 4: Filter for budget expense sheet
 * 5: Filter for green table
 */
const authorizedFilterTypeMode = [
	FILTER_TYPE.BUDGET_PER_CATEGORY,
	FILTER_TYPE.BUDGET_SALARIES,
	FILTER_TYPE.BUDGET_EXPENSES,
	FILTER_TYPE.BUDGET_POS,
	FILTER_TYPE.BUDGET_EXPENSE_SHEETS,
	FILTER_TYPE.GREEN_TABLE,
	FILTER_TYPE.JOB_REQUEST
];

export default {
	name: 'FilterBudgetAdvanced',
	components: {
		ChevronUp,
		ChevronDown,
		Info,
		CalendarDays,
		Download,
		RefreshCw,
		Treeselect,
		PlusCircle,
		SlidersHorizontal,
		GlobalFilter,
		AlertTriangle,
		TransportTypeSelector,
		'v-date-picker': DatePicker,
		FlagInputs
	},
	mixins: [languageMessages, globalMixin, BudgetMixin, CarbonMenuMixin, validationMixin, expensesFlagsMixin],
	props: {
		showUserInSectionWhen: { type: Boolean, default: false },
		showImport: { type: Boolean, default: false },
		hideExportSelection: { type: Boolean, default: false },
		// initFilter: { type: Object, default: {}, required: false},
		hideSupplierSection: { type: Boolean, default: false, required: false },
		hidePersonSection: { type: Boolean, default: false, required: false },
		hideCoTwoDetail: { type: Boolean, default: false, required: false },
		hideExpenseDetailsSection: { type: Boolean, default: false, required: false },
		showUserSelector: { type: Boolean, default: true, required: false },
		hideButtonExport: { type: Boolean, default: false, required: false },
		hideGeo: { type: Boolean, default: false },
		hideFunction: { type: Boolean, default: false },
		hideEntryDetailSection: { type: Boolean, default: true },
		showJobRequestEntryDetailSection: { type: Boolean, default: false },
		hideButtonRefreshData: { type: Boolean, default: false },
		hideDate: { type: Boolean, default: false },
		labelExpenseDetails: { type: String, default: '' },
		hideExpenseDetailsType: { type: Boolean, default: false },
		useDepartmentForExpense: { type: Boolean, default: false },
		useDepartmentForSalary: { type: Boolean, default: false },
		filterType: { type: Number, validator: (v) => authorizedFilterTypeMode.includes(v), required: true },
		hideExpenseDetailsDepartment: { type: Boolean, default: false, required: false },
		hideExpenseDetailsCategory: { type: Boolean, default: false, required: false },
		hideProcessFilter: { type: Boolean, default: true, required: false },
		hideInvoicedToProduction: { type: Boolean, default: true, required: false },
		isCarbon: { type: Boolean, default: false },
		hideFlagFilter: {
			type: Boolean,
			default: true,
			required: false
		}
	},
	data() {
		return {
			filterActive: {
				invoicedToProduction: null,
				processed: null,
				forForecastReportOnly: null,
				arrayType: [],
				country: '',
				state: '',
				city: '',
				userDepartment: null,
				userFunction: null,
				startDate: null,
				endDate: null,
				category: null,
				personId: null,
				status: null,
				type: null,
				department: null, // Department for expense
				supplierId: null,
				source: null,
				co2Type: null,
				type: null,
				subType: null,
				additionalSubType: null,
				reviewStatus: [],
				templateId: null,
				projectId: null,
				flagIds: []
			},
			equipmentOptions: [],
			tagVisible: false,
			countryNames: [],
			stateNames: [],
			functionNames: [],
			suppliers: [],
			mapCategories: [],
			haveFilter: false,
			users: [],
			jobRequestUser: [],
			projectList: [],
			cookiesName: 'filter_budget_advanced',
			showModalGlobalFilter: false,
			carbonTemplates: [],
			moment,
			masks: {
				input: 'DD/MM/YYYY'
			},
			isSubmitted: false,
			attrs: [
				{
					highlight: {
						fillMode: 'light'
					},
					dates: new Date()
				}
			],
			clearSelectedFlags: false
		};
	},
	computed: {
		...mapGetters({
			allDepartements: 'expenses/departments',
			allDepartementsForSalary: 'expenses/departmentsForSalary',
			filterBudget: 'global-filter/filterBudget'
		}),
		arrayTypeOptions() {
			const formMenu = this.FormMenu(11045);

			// Map 'value' to 'id'
			const mappedOptions = formMenu.map((option) => {
				return {
					id: option.value,
					label: option.text
				};
			});

			//console.log('mappedOptions', mappedOptions);
			return mappedOptions;
		},
		reviewStatusOptions() {
			let menues = this.FormMenu(CARBON_VERIFIED_STATUS_MENU);

			let result = [];
			menues.map((option) => {
				result.push({ id: option.value, label: option.text });
			});

			return result;
		},
		coTwoTypeOptions() {
			const menu = this.FormMenu(1334);

			const menuFiltered = menu.filter((option) => option.value !== 11 && option.value !== 12);

			//short result alphabetically with the option "undefined" allways at the first position.
			const undefinedOption = menuFiltered.filter((option) => option.value === 0);
			menuFiltered.shift();
			menuFiltered.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}
				return 0;
			});
			menuFiltered.unshift(undefinedOption[0]);

			return menuFiltered;
		},
		typeOptions() {
			if (this.filterActive.co2Type === 2) {
				return this.accomodationTypeOptions;
			}
			if (this.filterActive.co2Type === 4) {
				return this.transportOptions;
			}
			if (this.filterActive.co2Type === 5) {
				return this.wasteTypeOptions;
			}
			if (this.filterActive.co2Type === 7) {
				return this.hmcOptions;
			}
			if (this.filterActive.co2Type === 8) {
				return this.reasonSetCreationOptions;
			}
			if (this.filterActive.co2Type === 9) {
				return this.technicalProductionOptions;
			}
			if (this.filterActive.co2Type === 10) {
				return this.transportationShootingOptions;
			}
			if (this.filterActive.co2Type === 13) {
				return this.fuelTypeOptions;
			}
			if (this.filterActive.co2Type === 14) {
				return this.productionLogisticsOptions;
			}
			if (this.filterActive.co2Type === 17) {
				return this.geographicAreaOptions;
			}
			if (this.filterActive.co2Type === 18) {
				return this.vehicleType;
			}
			if (this.filterActive.co2Type === 19) {
				return this.co2TypeInsuranceAndService;
			}
			if (this.filterActive.co2Type === 20) {
				return this.activityTypeOptions;
			}
			return null;
		},
		subTypeOptions() {
			if (this.filterActive.co2Type === 20) {
				return this.optionsForCountries;
			}
			if ([6, 7, 8].includes(this.filterActive.type)) {
				return this.transportationSubTypeCarType;
			}
			if ([12].includes(this.filterActive.type)) {
				return this.transportationSubTypePlaneType;
			}
			if ([13].includes(this.filterActive.type)) {
				return this.transportationSubTypeScooterType;
			}
			if ([15].includes(this.filterActive.type)) {
				return this.transportationSubTypeTrainType;
			}
			if ([16].includes(this.filterActive.type)) {
				return this.transportationSubTypeVanType;
			}
			if (
				this.filterActive.type === SPE_VEHICLE_GENERATOR_TRUCK_TYPE ||
				this.filterActive.type === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE ||
				this.filterActive.type === SPE_VEHICLE_TRAILER_TYPE
			) {
				return this.equipmentOptions;
			}
			return null;
		},
		additionalSubTypeOptions() {
			if ([1, 2, 3, 4].includes(this.filterActive.subType)) {
				return this.statusOptions;
			}
			if ([5].includes(this.filterActive.subType)) {
				return this.transportationSubTypePlaneTypeAditionalType;
			}
			if (this.filterActive.co2Type === 20) {
				return this.reportingTypeOptions;
			}
			return null;
		},
		sourceOptions() {
			const options = this.FormMenu(11006);
			return options.filter((option) => option.value !== 3);
		},
		disableCategorySelector() {
			if (!this.useDepartmentForSalary) {
				if (this.filterActive.department === -1 || _.isNil(this.filterActive.department)) {
					return true;
				}

				return false;
			}
			return false;
		},
		/*optionsForCountries() {
			return this.countryNames;
		},*/
		optionsForStates() {
			return this.stateNames;
		},
		mapSuppliers() {
			const suppliers = this.suppliers.map((d) => {
				return {
					value: parseInt(d.id),
					text: d.name
				};
			});
			this.filterActive.supplierId = null;

			return [...suppliers];
		},
		canApplyFilter() {
			return (
				this.filterActive.country ||
				this.filterActive.state !== '' ||
				this.filterActive.city !== '' ||
				this.filterActive.userDepartment ||
				this.filterActive.userFunction ||
				this.filterActive.supplierId ||
				this.filterActive.startDate ||
				this.filterActive.endDate ||
				this.filterActive.personId ||
				this.filterActive.status ||
				this.filterActive.status === 0 ||
				this.filterActive.department ||
				this.filterActive.department === 0 ||
				this.filterActive.category ||
				this.filterActive.source ||
				this.filterActive.co2Type ||
				this.filterActive.co2Type === 0 ||
				this.filterActive.type ||
				this.filterActive.type === 0 ||
				this.filterActive.subType ||
				this.filterActive.subType === 0 ||
				this.filterActive.additionalSubType ||
				this.filterActive.additionalSubType === 0 ||
				this.filterActive.reviewStatus.length > 0 ||
				this.filterActive.processed !== null ||
				//this.filterActive.forForecastReportOnly !== null ||
				this.filterActive.arrayType !== null ||
				this.filterActive.invoicedToProduction !== null ||
				this.filterActive.templateId ||
				this.filterActive.projectId ||
				+this.filterActive.templateId > 0 ||
				this.filterActive.flagIds.length > 0
			);
		},
		statuses() {
			return this.FormMenu(1008);
		},
		jobStatuses() {
			return this.FormMenu(1443);
		},
		typeExpenses() {
			return [
				// { value: -1, text: this.FormMSG(369, 'Select a type') },
				{ value: 0, text: this.FormMSG(327, 'Ticket') },
				{ value: 1, text: this.FormMSG(328, 'Invoice') },
				{ value: 2, text: this.FormMSG(329, 'Travel') }
			];
		},
		titleExpenseDetail() {
			return this.labelExpenseDetails;
		},
		filterActiveStartDate() {
			return this.filterActive.startDate;
		},
		filterActiveEndDate() {
			return this.filterActive.endDate;
		},
		filterActivePersonId() {
			return this.filterActive.personId;
		},
		filterActiveSource() {
			return this.filterActive.source !== null;
		},
		filterActiveCategory() {
			return this.filterActive.category !== null && this.filterActive.category !== undefined;
		},
		filterActiveSupplier() {
			return this.filterActive.supplierId !== null;
		},
		filterActiveReviewStatus() {
			return this.filterActive.reviewStatus.length !== 0;
		},
		filterActiveTemplate() {
			return this.filterActive.templateId !== null || this.filterActive.templateId > 0;
		},
		filterActiveArrayType() {
			return this.filterActive.arrayType.length !== 0;
		},
		filterActiveCo2Type() {
			return this.filterActive.co2Type !== null;
		},
		filterActiveCo2Detail1() {
			return this.filterActive.type !== null;
		},
		filterActiveCo2Detail2() {
			return this.filterActive.subType !== null;
		},
		filterActiveCo2Detail3() {
			return this.filterActive.additionalSubType !== null;
		},
		filterActiveStatus() {
			return this.filterActive.status !== null;
		},
		filterActiveDepartment() {
			return this.filterActive.department !== null;
		},
		filterActiveUserDepartment() {
			return this.filterActive.userDepartment !== null;
		},
		filterActiveUserFunction() {
			return this.filterActive.userFunction !== null;
		},
		filterActiveProjectId() {
			return this.filterActive.projectId !== null;
		},
		filterActiveState() {
			return this.filterActive.state !== '';
		},
		filterActiveCountry() {
			return this.filterActive.country !== '';
		},
		filterActiveProcessed() {
			return this.filterActive.processed !== null;
		},
		filterActiveFlag() {
			return this.filterActive.flagIds.length > 0;
		},
		filterFunctions() {
			return [
				{ name: 'dateRange', function: this.filterActiveDateRange, value: this.filterActiveDateRange },
				{ name: 'personId', function: this.filterActivePersonId, value: this.getUser(this.filterActive.personId) },
				{ name: 'source', function: this.filterActiveSource, value: this.getSource(this.filterActive.source) },
				{ name: 'category', function: this.filterActiveCategory, value: this.getCategory(this.filterActive.category) },
				{ name: 'supplierId', function: this.filterActiveSupplier, value: this.getSupplier(this.filterActive.supplierId) },
				{ name: 'reviewStatus', function: this.filterActiveReviewStatus, value: this.getReviewStatusFilterActive(this.filterActive.reviewStatus) },
				{ name: 'template', function: this.filterActiveTemplate, value: this.getTemplate(this.filterActive.templateId) },
				{ name: 'arrayType', function: this.filterActiveArrayType, value: this.getArrayTypeFilterActive(this.filterActive.arrayType) },
				{ name: 'co2Type', function: this.filterActiveCo2Type, value: this.getCo2Detail(this.filterActive.co2Type) },
				{ name: 'type', function: this.filterActiveCo2Detail1, value: this.getType(this.filterActive.type) },
				{ name: 'subType', function: this.filterActiveCo2Detail2, value: this.getCo2Detail2(this.filterActive.subType) },
				{ name: 'additionalSubType', function: this.filterActiveCo2Detail3, value: this.getCo2Detail3(this.filterActive.additionalSubType) },
				{ name: 'status', function: this.filterActiveStatus, value: this.getStatus(this.filterActive.status) },
				{ name: 'department', function: this.filterActiveDepartment, value: this.getDepartment(this.filterActive.department) },
				{ name: 'userDepartment', function: this.filterActiveUserDepartment, value: this.getDepartment(this.filterActive.userDepartment) },
				{ name: 'userFunction', function: this.filterActiveUserFunction, value: this.getUserFunction(this.filterActive.userFunction) },
				{ name: 'projectId', function: this.filterActiveProjectId, value: this.getProjectId(this.filterActive.projectId) },
				{ name: 'state', function: this.filterActiveState, value: this.getState(this.filterActive.state) },
				{ name: 'country', function: this.filterActiveCountry, value: this.getCountry(this.filterActive.country) },
				{ name: 'processed', function: this.filterActiveProcessed, value: this.getProcessedValue(this.filterActive.processed) },
				{ name: 'flag', function: this.filterActiveFlag, value: this.getFlags(this.filterActive.flagIds) }
			].filter((filter) => filter.value !== null && filter.value !== undefined);
		},
		filterActiveDateRange() {
			if (this.filterActiveStartDate && this.filterActiveEndDate) {
				return moment(this.filterActive.startDate).format('DD/MM/YYYY') + ' - ' + moment(this.filterActive.endDate).format('DD/MM/YYYY');
			} else if (this.filterActiveStartDate && !this.filterActiveEndDate) {
				return moment(this.filterActive.startDate).format('DD/MM/YYYY');
			} else if (!this.filterActiveStartDate && this.filterActiveEndDate) {
				return moment(this.filterActive.endDate).format('DD/MM/YYYY');
			} else {
				return null; // Handle other cases as needed
			}
		},
		canEditExpense() {
			return !!store.canEditExpense();
		}
	},
	watch: {
		filterBudget: {
			async handler(newVal) {
				if (this.filterType === FILTER_TYPE.BUDGET_PER_CATEGORY) {
					if (!_.isNil(newVal.forBudgetPerCategory)) {
						this.filterActive = newVal.forBudgetPerCategory;
					}
				} else if (this.filterType === FILTER_TYPE.BUDGET_SALARIES) {
					if (!_.isNil(newVal.forBudgetSalaries)) {
						this.filterActive = newVal.forBudgetSalaries;
					}
				} else if (this.filterType === FILTER_TYPE.BUDGET_EXPENSES) {
					if (!_.isNil(newVal.forBudgetExpenses)) {
						this.filterActive = newVal.forBudgetExpenses;
						// console.log(this.filterActive)
						// alert("EXPENSES")
					}
				} else if (this.filterType === FILTER_TYPE.BUDGET_POS) {
					if (!_.isNil(newVal.forBudgetPO)) {
						this.filterActive = newVal.forBudgetPO;
					}
				} else if (this.filterType === FILTER_TYPE.BUDGET_EXPENSE_SHEETS) {
					if (!_.isNil(newVal.forBudgetExpenseSheet)) {
						this.filterActive = newVal.forBudgetExpenseSheet;
					}
				} else if (this.filterType === FILTER_TYPE.GREEN_TABLE) {
					if (!_.isNil(newVal.forGreenTable)) {
						this.filterActive = newVal.forGreenTable;
					}
				}

				if (this.filterActive.userDepartment) {
					await this.handleChangeDepartment(this.filterActive.userDepartment);
				}

				// if (this.filterActive.department) {
				// 	await this.handleInputDepartmentExpense(this.filterActive.department);
				// }

				if (this.filterActive.country) {
					await this.handleChangeCountry(this.filterActive.country);
				}

				this.getHaveFilter();
				if (this.haveFilter) {
					this.tagVisible = true;
				}

				this.filterActive = {
					...this.filterActive,
					startDate: !_.isNil(this.filterActive.startDate) ? moment(this.filterActive.startDate).format('YYYY-MM-DD') : null,
					endDate: !_.isNil(this.filterActive.endDate) ? moment(this.filterActive.endDate).format('YYYY-MM-DD') : null
				};
			},
			immediate: true
			// deep: true
		},
		filterActive: {
			async handler(newVal) {
				//console.log('watcher', newVal);
				if (
					newVal.type === SPE_VEHICLE_GENERATOR_TRUCK_TYPE ||
					newVal.type === SPE_VEHICLE_PRODUCTION_TRUCK_TYPE ||
					newVal.type === SPE_VEHICLE_TRAILER_TYPE
				) {
					this.equipmentOptions = await this.getEquipmentOptions(newVal.type);
				}
			},
			deep: true
		}
	},
	async created() {
		await this.loadCountryNames();
		//console.log('filteractive CREATED', this.filterActive);
		if (this.showJobRequestEntryDetailSection) {
			await this.getSuppliers(9, true);
			await this.getJobRequestUsers();
			await this.getAllProjectList();
		} else {
			await this.getSuppliers(0, false);
			await this.getUsers();
		}

		await this.getAllDepartments();
		await this.getCategories();

		if (this.filterType === FILTER_TYPE.GREEN_TABLE) {
			await this.getCarbonTemplates();
		}

		await getProjectFlags(FLAG_TYPE.DEFAULT_FLAG)
			.then((records) => {
				this.flags = records.customFlags;
			})
			.catch((error) => {
				console.log({ error });
			});
	},
	methods: {
		...mapActions({
			setMyFilterActive: 'global-filter/setMyFilterActive',
			getAllDepartments: 'expenses/getAllDepartments'
		}),
		async getCarbonTemplates() {
			this.carbonTemplates = await getCarbonTemplates({}, true);
		},
		async getAllProjectList() {
			await getAllProjects().then((res) => {
				this.projectList = res;
			});
		},
		handleChangeCo2Type() {
			(this.filterActive.type = null), (this.filterActive.subType = null), (this.filterActive.additionalSubType = null);
		},
		handleChangeType(payload) {
			(this.filterActive.subType = null), (this.filterActive.additionalSubType = null);
			//console.log('handleChangeType', payload);
		},
		handleChangeSubType(payload) {
			//console.log('handleChangeSubType', payload);
			this.filterActive.subType = payload;
			this.filterActive.additionalSubType = null;
		},
		handleChangeAdditionalSubType(payload) {
			this.filterActive.additionalSubType = payload;
		},
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail, specialEquipment }) {
			this.filterActive.type = type;
			this.filterActive.subType = detail;
			this.filterActive.additionalSubType = additionalDetail;
		},
		handleInputReviewStatus(payload) {
			this.filterActive.reviewStatus = payload;
		},
		handleInputCategory(payload) {
			this.filterActive.category = payload;
		},
		handleInputArrayType(payload) {
			this.filterActive.arrayType = payload;
		},
		redirectToImportScreen() {
			this.$router.push('/imports');
		},
		async getJobRequestUsers() {
			await getListUserInJobRequest().then((res) => {
				let result = [];
				if (res.length > 0) {
					res.forEach((user) => {
						result.push({
							value: parseInt(user.id, 10),
							text: user.fullName
						});
					});
				}
				this.jobRequestUser = result;
			});
		},
		setText(text) {
			if (text !== undefined && text.length < 4) {
				return text + ' ...';
			}
			return text;
		},
		setTextValue(text) {
			if (text !== undefined) {
				if (text.length > 20) {
					return text.substring(0, 17) + ' ...';
				}
				return text.substring(0, 20);
			}
		},
		handleChangeStartDate() {
			// alert('HERE');
			// alert('HERE');
		},
		handleInputState(payload) {
			this.filterActive.state = payload;
		},
		reduceSupplier(option) {
			return parseInt(option.id);
		},
		handleInputSupplier(payload) {
			this.filterActive.supplierId = payload;
		},
		async handleFunctionInput(payload) {
			// console.log(payload);
			this.filterActive.userFunction = payload;
			this.filterActive.personId = null;
			await this.getUsers(+this.filterActive.userDepartment, +payload);
		},
		handleInputStartDate(payload) {
			if (!_.isNil(this.filterActive.startDate)) {
				this.filterActive.startDate = payload.id;
			}
		},
		handleInputEndDate(payload) {
			if (!_.isNil(this.filterActive.endDate)) {
				this.filterActive.endDate = payload.id;
			}
		},
		async handleInputDepartmentExpense(payload, initCategory = false) {
			// if (initCategory) {
			// 	this.filterActive.category = null;
			// }
			// if (_.isNil(payload)) {
			// 	this.mapCategories = [];
			// 	this.filterActive.category = null;
			// } else {
			// 	await this.getCategories(0);
			// }
		},
		async handleInputDepartmentSalaries(payload, initCategory = false) {
			if (initCategory) {
				this.filterActive.category = null;
			}
			// alert('miditra ato');
			await this.getCategories(1);
		},
		async getCategories(contentType = 0) {
			this.mapCategories = [];
			await getBudgetHierarchicalCategoryJson(-2, true, 0, -1, false).then((result) => {
				this.mapCategories = removeAttributeTree(result, 'children', null);
			});
		},
		handleClickRefreshData() {
			this.$bus.$emit('filter-budget-advanced:refresh-data');
		},
		async getUsers(departmentId = -1, functionId = 0) {
			const users = await getUsers({ myProject: true }, 0, departmentId, functionId);
			let result = [];

			if (users.length > 0) {
				users.forEach((user) => {
					result.push({
						value: parseInt(user.id, 10),
						text: user.fullName
					});
				});
			}

			this.users = result;
		},
		async handleApplyFilter(e) {
			e.preventDefault();
			this.clearSelectedFlags = false;

			this.setMyFilterActive({
				newValue: { ...this.filterActive, id: 0 },
				filterType: this.filterType
			});
			//console.log('filterType', this.filterType); //5
			this.emitFilters();
			this.getHaveFilter();
			this.handleClickShowFilter(false);
		},
		handleClickExportSelection() {
			this.tagVisible = true;

			this.$emit('filter-budget-advanced:click-selection', {
				startDate: !_.isNil(this.filterActive.startDate) ? moment(this.filterActive.startDate).format('YYYY-MM-DD') + 'T00:00:00Z' : null,
				endDate: !_.isNil(this.filterActive.endDate) ? moment(this.filterActive.endDate).format('YYYY-MM-DD') + 'T23:59:59Z' : null,
				userDepartment: !_.isNil(this.filterActive.userDepartment) ? +this.filterActive.userDepartment : null,
				userFunction: !_.isNil(this.filterActive.userFunction) ? +this.filterActive.userFunction : null,
				personId: !_.isNil(this.filterActive.personId) ? +this.filterActive.personId : null,
				status: !_.isNil(this.filterActive.status) ? +this.filterActive.status : null,
				department: !_.isNil(this.filterActive.department) ? +this.filterActive.department : null,
				category: !_.isNil(this.filterActive.category) ? +this.filterActive.category : null,
				supplierId: !_.isNil(this.filterActive.supplierId) ? +this.filterActive.supplierId : null,
				country: this.filterActive.country,
				state: this.filterActive.state,
				city: this.filterActive.city,
				type: !_.isNil(this.filterActive.type) ? +this.filterActive.type : null,
				sendEmail: false,
				processed: this.filterActive.processed,
				forForecastReportOnly: this.filterActive.forForecastReportOnly,
				invoicedToProduction: this.filterActive.invoicedToProduction,
				groupBySheets: true,
				flagIds: this.filterActive.flagIds
			});
		},
		emitFilters() {
			const resultReturnEmit = this.returnFilter(this.filterActive);

			this.$bus.$emit('filter-budget-advanced:change', {
				...resultReturnEmit,
				filterType: this.filterType
			});
		},
		async handleChangeDepartment(payload, sendEmit = true) {
			this.filterActive.userFunction = null;
			this.filterActive.personId = null;
			await this.getFunctions(+payload);
			await this.getUsers(+payload);
		},
		async getFunctions(id) {
			const functions = await getFunctions(id);

			let resultBudgetDetails = [];

			for (let i = 0; i < functions.length; i++) {
				const element = functions[i];
				resultBudgetDetails.push({
					value: element.value,
					message: element.costCenter + ' - ' + element.message
				});
			}

			this.functionNames = resultBudgetDetails;
		},
		handleClickShowFilter(value) {
			this.tagVisible = value;
		},
		handleChangeCountry(sendEmit = true) {
			this.filterActive.state = '';
			this.$nextTick(async () => {
				await this.loadStateNames();
			});
		},
		async loadStateNames() {
			let ar = [];

			const stateData = await getSuppliersStates(this.filterActive.country);

			for (let i = 1; i < stateData.length; i++) {
				let curMenuItem = {};
				curMenuItem.value = stateData[i];
				curMenuItem.text = stateData[i];
				ar.push(curMenuItem);
			}

			this.stateNames = ar;
		},
		async loadCountryNames() {
			let ar = [];

			ar.push({
				value: '',
				text: this.FormMSG(122, 'Select country')
			});

			const countryData = await getSuppliersCountries();

			for (let i = 1; i < countryData.length; i++) {
				let curMenuItem = {};
				curMenuItem.value = countryData[i];
				curMenuItem.text = countryData[i];
				ar.push(curMenuItem);
			}

			this.countryNames = ar;
		},
		resetFilter() {
			(this.filterActive = {
				invoicedToProduction: null,
				processed: null,
				forForecastReportOnly: null,
				arrayType: [],
				country: '',
				state: '',
				city: '',
				userDepartment: null,
				userFunction: null,
				startDate: null,
				endDate: null,
				category: null,
				personId: null,
				status: null,
				type: null,
				department: null, // Department for expense
				supplierId: null,
				source: null,
				co2Type: null,
				type: null,
				subType: null,
				additionalSubType: null,
				reviewStatus: [],
				templateId: null,
				projectId: null,
				flagIds: []
			}),
				this.setMyFilterActive({
					newValue: null,
					filterType: this.filterType
				});
			this.emitClearFilters();
			this.getHaveFilter();
			this.clearSelectedFlags = true;
		},
		emitClearFilters() {
			this.$bus.$emit('filter-budget-advanced:clear-filters', this.filterActive);
		},
		getHaveFilter() {
			this.haveFilter =
				this.filterActive.country !== '' ||
				this.filterActive.state !== '' ||
				this.filterActive.city !== '' ||
				this.filterActive.userDepartment ||
				this.filterActive.userFunction ||
				this.filterActive.supplierId ||
				this.filterActive.startDate !== null ||
				this.filterActive.endDate !== null ||
				this.filterActive.personId ||
				this.filterActive.status ||
				this.filterActive.type ||
				this.filterActive.department ||
				this.filterActive.category ||
				this.filterActive.source ||
				this.filterActive.co2Type ||
				this.filterActive.co2Type === 0 ||
				this.filterActive.reviewStatus.length > 0 ||
				this.filterActive.arrayType.length > 0 ||
				this.filterActive.processed !== null ||
				this.filterActive.forForecastReportOnly !== null ||
				this.filterActive.invoicedToProduction !== null ||
				this.filterActive.templateId ||
				+this.filterActive.templateId > 0 ||
				this.filterActive.projectId ||
				this.filterActive.flagIds.length > 0;

			this.$emit('filter-budget-advanced:have-filter', { haveFilter: this.haveFilter });
		},
		async getSuppliers(type, forJobRequest) {
			const result = await getSuppliers({
				name: null,
				type: type,
				ForJobRequest: forJobRequest
			});
			this.suppliers = result;
		},
		getUser(userId) {
			if (this.filterActivePersonId) {
				const listUser = this.showJobRequestEntryDetailSection ? this.jobRequestUser : this.users;
				const user = listUser.find((user) => user.value === userId);
				if (user) {
					return user.text;
				}
			}
		},
		getSource(sourceId) {
			if (this.filterActiveSource) {
				const source = this.sourceOptions.find((source) => source.value === sourceId);
				if (source) {
					return source.text;
				}
			}
		},
		getArrayType(arrayTypeId) {
			if (this.filterActiveArrayType) {
				const report = this.arrayTypeOptions.find((report) => report.value === arrayTypeId);
				if (report) {
					return report.text;
				}
			}
		},
		getSupplier(supplierId) {
			if (this.filterActiveSupplier) {
				const supplier = this.suppliers.find((supplier) => +supplier.id === +supplierId);
				if (supplier) {
					return supplier.name;
				}
			}
		},
		getArrayTypeName(reportId) {
			const report = this.arrayTypeOptions.find((report) => +report.id === +reportId);
			if (report) {
				return report.label;
			}
		},
		getArrayTypeFilterActive(arrayType) {
			if (this.filterActiveArrayType) {
				let result = '';
				arrayType.forEach((id, index) => {
					const reportName = this.getArrayTypeName(id);
					if (reportName) {
						result += reportName;
						if (index !== arrayType.length - 1) {
							result += ', ';
						}
					}
				});
				return result;
			}
		},
		getCategory(categoryId) {
			if (this.filterActiveCategory) {
				// Define a recursive function to search for the category in nested structures
				const findCategoryInNested = (categories, id) => {
					for (const category of categories) {
						if (+category.id === +id) {
							if (+category.id === 0) {
								return null;
							}
							return category.label; // Found the category, return its label
						}
						if (category.children) {
							const found = findCategoryInNested(category.children, id);
							if (found) {
								return found; // Found the category in children, return its label
							}
						}
					}
					return null; // Category not found
				};

				// Call the recursive function to search for the category label
				const categoryLabel = findCategoryInNested(this.mapCategories, categoryId);
				//console.log(categoryLabel);
				return categoryLabel;
			}
		},
		getTemplate(templateId) {
			if (this.filterActiveTemplate) {
				const carbonTemplate = this.carbonTemplates.find((item) => +item.id === templateId);
				if (carbonTemplate) return carbonTemplate.name;
			}
		},
		getReviewStatus(statusId) {
			if (this.filterActiveReviewStatus) {
				const status = this.reviewStatusOptions.find((status) => +status.id === +statusId);
				if (status) {
					return status.label;
				}
			}
		},
		getReviewStatusFilterActive(reviewStatus) {
			if (this.filterActiveReviewStatus) {
				let result = '';
				reviewStatus.forEach((id, index) => {
					const statusName = this.getReviewStatus(id);
					if (statusName) {
						result += statusName;
						if (index !== reviewStatus.length - 1) {
							result += ', ';
						}
					}
				});
				return result;
			}
		},
		getCo2Detail(coTwoId) {
			if (this.filterActiveCo2Type) {
				const coTwo = this.coTwoTypeOptions.find((coTwo) => +coTwo.value === +coTwoId);
				if (coTwo) {
					return coTwo.text;
				}
			}
		},
		getType(id) {
			if (this.filterActiveCo2Detail1) {
				let list = [];
				//console.log('typeExpenses', this.typeExpenses);
				//console.log('typeOptions', this.typeOptions);
				//console.log('filterType', this.filterType);
				if (this.filterType === FILTER_TYPE.BUDGET_EXPENSES || this.filterType === FILTER_TYPE.BUDGET_EXPENSE_SHEETS) {
					list = this.typeExpenses;
				} else {
					list = this.typeOptions;
				}

				const item = list.find((item) => +item.value === +id);
				if (item) {
					return item.text;
				}
			}
		},
		getCo2Detail2(coTwoId) {
			if (this.filterActiveCo2Detail2) {
				if (coTwoId) {
					//console.log('thisSubtypeOptons', this.subTypeOptions);
					const coTwo = this.subTypeOptions.find((coTwo) => +coTwo.value === +coTwoId);
					if (coTwo) {
						return coTwo.text;
					}
				}
			}
		},
		getCo2Detail3(coTwoId) {
			if (this.filterActiveCo2Detail3) {
				if (coTwoId) {
					const coTwo = this.additionalSubTypeOptions.find((coTwo) => +coTwo.value === +coTwoId);
					if (coTwo) {
						return coTwo.text;
					}
				}
			}
		},
		getStatus(id) {
			if (this.filterActiveStatus) {
				//console.log('status', id);

				const list = this.showJobRequestEntryDetailSection ? this.jobStatuses : this.statuses;
				const status = list.find((status) => +status.value === +id);
				if (status) {
					//console.log('status', status);

					return status.text;
				}
			}
		},
		getDepartment(id) {
			if (this.filterActiveDepartment) {
				const department = this.allDepartements.find((department) => +department.value === +id);
				if (department) {
					return department.message;
				}
			}
		},
		getUserFunction(id) {
			if (this.filterActiveUserFunction) {
				const userFunction = this.functionNames.find((user) => +user.value === +id);
				if (userFunction) {
					return userFunction.message;
				}
			}
		},
		getProjectId(id) {
			if (this.filterActiveProjectId) {
				const project = this.additionalSubTypeOptions.find((project) => +project.value === +id);
				if (project) {
					return project.text;
				}
			}
		},
		getPersonId(id) {
			if (id) {
				const list = this.showJobRequestEntryDetailSection ? this.jobRequestUser : this.users;
				const person = list.find((person) => +person.value === +id);
				if (person) {
					return person.text;
				}
			}
		},
		getState(id) {
			if (this.filterActiveState) {
				const state = this.stateNames.find((state) => +state.value === +id);
				if (state) {
					return state.text;
				}
			}
		},
		getCountry(id) {
			if (this.filterActiveCountry) {
				const country = this.optionsForCountries.find((country) => +country.value === +id);
				if (country) {
					return country.text;
				}
			}
		},
		getProcessedValue(processed) {
			if (this.filterActiveProcessed) {
				const value = processed ? this.FormMSG(514, 'Processed') : this.FormMSG(555, 'Not Processed');
				return value;
			}
			return null;
		},
		getFlags(flagIds) {
			if (this.filterActiveFlag) {
				let text = '';

				for (let i = 0; i < this.flags.length; i++) {
					for (let j = 0; j < flagIds.length; j++) {
						const flagItem = this.flags[i].projectFlagItem.filter((item) => +item.id === flagIds[j].flagId);
						if (flagItem.length > 0) {
							const reg = new RegExp(flagItem[0].short.toUpperCase(), 'g');

							// fix duplicated text
							if (reg.test(text)) continue;

							if (!_.isEmpty(text)) {
								text += ', ';
							}
							text += `["${this.flags[i].name.toUpperCase()}": '${flagItem[0].short.toUpperCase()}']`;
						}
					}
				}

				return text;
			}
		},
		selectableTypeItem(item) {
			return _.isNil(item.disabled) || (!_.isNil(item.disabled) && item.disabled === false);
		},
		onFlagSelected(payload) {
			let flagIds = [];

			for (let i = 0; i < payload.length; i++) {
				flagIds.push({ flagId: +payload[i].item.id });
			}

			this.filterActive.flagIds = flagIds;
		}
	},
	validations() {
		return {
			filterActive: {
				startDate: {
					required,
					dateFrValid
				},
				endDate: {
					required,
					dateFrValid
				}
			}
		};
	}
};
</script>

<style>
.active-filter-input {
	background-color: #e7f2ff !important;
}

.active-filter-input .vs__search::placeholder {
	background-color: #e7f2ff !important;
}

.active-filter-input-v-select .vs__search::placeholder,
.active-filter-input-v-select .vs__dropdown-toggle {
	border-radius: 5px;
	background-color: #e7f2ff !important;
}

.active-filter-input .vue-treeselect__input-container {
	color: #e7f2ff !important;
}

.active-filter-input-tree-select .vue-treeselect__control {
	background-color: #e7f2ff !important;
}

.active-filter-input-tree-select .vue-treeselect__control .vue-treeselect__multi-value-item {
	background: #ffffff;
}
</style>
