var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _vm.filterActive && !_vm.showInputFilter
      ? _c(
          "div",
          { staticClass: "container-field p-5-px mt-n-5 mr-3 pos-relative" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            value: _vm.filterActive.filterName,
                            disabled: "",
                          },
                        }),
                        _c(
                          "b-input-group-append",
                          { staticClass: "cursor-pointer" },
                          [
                            _c(
                              "b-input-group-text",
                              { staticClass: "btn-search" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn-transparent",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleClickInput(
                                          _vm.filterActive
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("Edit2", {
                                      staticClass: "icon",
                                      attrs: {
                                        color: "#FFFFFF",
                                        size: 16,
                                        "stroke-width": 2.5,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.showInputFilter
      ? _c(
          "div",
          { staticClass: "container-field p-5-px mt-n-5 mr-3 pos-relative" },
          [
            _c(
              "div",
              {
                staticClass: "pos-absolute custom-dissimissible-filter",
                staticStyle: { top: "-12px", right: "-7px" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function ($event) {
                        _vm.showInputFilter = false
                      },
                    },
                  },
                  [
                    _c("XCircle", {
                      attrs: { size: 16, color: "#06263e", fill: "#f3f4f5" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        size: "sm",
                        placeholder: _vm.FormMSG(31, "Filter name"),
                      },
                      model: {
                        value: _vm.filterName,
                        callback: function ($$v) {
                          _vm.filterName = $$v
                        },
                        expression: "filterName",
                      },
                    }),
                  ],
                  1
                ),
                _c("b-col", { attrs: { cols: "6" } }, [
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _vm.forCreateFilter
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary", size: "sm" },
                              on: { click: _vm.handleSubmit },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(66, "Create")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.forCreateFilter
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-danger", size: "sm" },
                              on: { click: _vm.handleClickDeleteFilter },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(57, "Delete")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      [
        _c(
          "b-dropdown",
          {
            staticClass: "mr-2",
            attrs: { id: "dropdown-1", variant: "outline-success" },
            scopedSlots: _vm._u([
              {
                key: "button-content",
                fn: function () {
                  return [
                    _c(
                      "span",
                      [
                        _c("SlidersHorizontal", { attrs: { size: 16 } }),
                        _vm._v(
                          " " + _vm._s(_vm.FormMSG(294, "Saved filter")) + " "
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "b-dropdown-item",
              { on: { click: _vm.handleClickNewFilter } },
              [
                _c("PlusCircle", { attrs: { size: 16 } }),
                _vm._v(
                  " " + _vm._s(_vm.FormMSG(291, "Save current filter")) + " "
                ),
              ],
              1
            ),
            _vm.filterOptions.length > 0 ? _c("b-dropdown-divider") : _vm._e(),
            _vm._l(_vm.filterOptions, function (filter) {
              return _c(
                "b-dropdown-item",
                {
                  key: filter.id,
                  attrs: {
                    active:
                      _vm.filterActive && filter.id === _vm.filterActive.id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickFilter(filter)
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(filter.filterName) + "\n\t\t\t")]
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }